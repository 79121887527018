.error-text {
    color: #DD3D05;
    margin-left:6px;
    text-align:center;
    margin-bottom: 10px;
    margin-top:-10px;
    font-family: 'Gentium Book Plus', serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    text-shadow: 1px 1px rgba(250,250,250,.3);
    /* border: 1px solid #DD3D05; */
    background-color: #fce4e4;
   
  padding: 10px 20px;
  }
.error-text1 {
    color: #DD3D05;
    margin-left:6px;
    text-align:center;
    margin-bottom: 15px;
    margin-top:-10px;
    font-family: 'Gentium Book Plus', serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    text-shadow: 1px 1px rgba(250,250,250,.3);
    /* border: 1px solid #DD3D05; */
    background-color: #fce4e4;
   
  padding: 10px 20px;
  }

  .eye{

    position: relative;
    display: flex;
    /* margin-bottom: 14px; */
  
  }
  