@media only screen and (max-width: 800px) {
  /* .test{
      margin-left:12px!important;
  } */
  .footer-widget {
    margin-left: 0px !important;
  }
  .sticky {
    display: block;
  }
  .welcome {
    margin-top: 0px !important;
  }
  .imageLogo {
    width: 75% !important;
  }
}

.logo1 {
  /* margin-left: -28px; */
  max-height: 50px;
}
@media screen and (max-width: 768px) {
  .sticky {
    display: flex !important;
  }
  .log {
    display: none;
  }

  .logo1 {
    /* margin-left: -28px; */
    max-height: 37.48px;
  }
}

.social-icon-style-4 a {
  background-color: #f2f2f2;
  width: 42px;
  height: 42px;
  line-height: 42px;
  font-size: 16px;
  color: #919191;
  display: inline-block;
  text-align: center;
  margin-right: 7px;
}
.social-icon-style-4 a:hover {
  background-color: #dd3d05;
  color: #ffffff;
}
