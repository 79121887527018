.button-box button {
  background-color: #dd3d05;
  color: #fff;
  border: medium none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 15px;
  line-height: 1;
  margin-left: 7px;
  padding: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .link {
    margin-left: 32px;
  }
  .modalLogin {
    width: 100% !important;
  }
  /* .cartbtn{
      font-size:12px!important;
    } */
  .card-text {
    width: 40%;
  }
}
.custom-swiper .swiper-button-prev {
  margin-left: 20px; /* Adjust the margin-left value as needed */
}

.custom-swiper .swiper-button-next {
  margin-right: 50px; /* Adjust the margin-right value if needed */
}
