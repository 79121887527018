@import url(https://fonts.googleapis.com/css?family=Lato:700);
/* https://codepen.io/nxworld/pen/oLdoWb */
/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 239px;
  padding: 4px 0;
  background-color: #DD3D05;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 14px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

.form-check-input:focus{
  outline: none !important;
  box-shadow: none;
}
#drop:focus{
  outline: none !important;
  /* border:black !important; */
  box-shadow: none;
  outline-color:black;
  outline-style: solid;
}

/* .test:focus {
  outline-color: #ffffff;
  outline-style: solid;
}  */


/* .form-check-input[type=radio]{
  background-color:#DD3D05!important;
  border-color:#DD3D05!important; 
} */
/* top right*/

/* Ribbons start */
.ribbon-top-right {
  top: -1px;
  right: -1px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {

  top: 23px;
  transform: rotate(45deg);
}

/* Ribbons End*/

/* Ribbons1 start */
.ribbon1-top-right {
  top: -10px;
  right: -10px;
}
.ribbon1-top-right::before,
.ribbon1-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon1-top-right::before {
  top: 0;
  left: 0;
}
.ribbon1-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon1-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* Ribbons1 End*/


.button-box a {
  background-color: #DD3D05;
  color: #fff;
  border: medium none;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  padding: 14px 30px 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  z-index: 1;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

input[type=number] {
  -moz-appearance: textfield;
}

#rotate{
  vertical-align:top;
	transform:rotate(7deg);
  -ms-transform:rotate(90deg); /* IE 9 */
  -moz-transform:rotate(90deg); /* Firefox */
  -webkit-transform:rotate(90deg); /* Safari and Chrome */
  -o-transform:rotate(90deg); /* Opera */}
    
#vert{
	height: auto;
	min-width: auto;
}




/* rightside modal start */

#hp-ctn-howItWorks
{
  cursor: pointer;
    position:fixed;
    top:50%;
    right: 0px;
    padding:0px;
    margin:0px;
    width: 28px;
    height:111px;
    background:#DD3D05;
    z-index:15;
    border-radius: 25px 0px 0px 25px;
}

#hp-ctn-howItWorks img
{
    margin: 15px 0px 0px 13px;
}

#hp-ctn-howItWorks p
{
    /* color: #fff; */
    -moz-transform:rotate(-90deg);
    -ms-transform:rotate(-90deg);
    -o-transform:rotate(-90deg);
    -webkit-transform:rotate(-90deg);
}
.c{
  width:100%;
  height:100%;
  color:white;  
  position:relative;
  Top:20px;
  left:46px
  }
  .fittedRow{
    display:flex;
  }
  /* rightside modal end */
  @media only screen and (max-width: 767px) {
    #quickview{
      display: none;
    }
  .wishshare{
    margin-bottom:20px!important;
  }
  .reviewClass{
    width:50%;
  }
  .price{
    margin-top:20px!important;
    margin-bottom: 20px!important;
    margin-left:10px!important;
  }
  .price1{
    margin-top: 20px!important;
  }
  .icons{
    display:none!important;
   
  }
  .cartIcon{
    margin-left: 0px !important;;
  }
  .fittedRow{
    display: block !important;
  }
   /* .icons{
    display:none;
  } */

  }

  @media only screen and (max-width: 1024px) {
    .icons{
      display:none!important;
     
    }
    .cartIcon{
      margin-left: 0px !important;;
    }
    .error-text{
      margin-top:15px;
      margin-left:10px;
    }
  }
  @media only screen and (max-width: 1280px) {
    .icons{
      display:none!important;
     
    }
    .cartIcon{
      margin-left: 0px !important;;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: #DD3D05 !important;
  }